import prebid from 'prebid.js';
import 'prebid.js/modules/ad2ictionBidAdapter';
import 'prebid.js/modules/appierAnalyticsAdapter';
import 'prebid.js/modules/appnexusBidAdapter';
import 'prebid.js/modules/atsAnalyticsAdapter';
import 'prebid.js/modules/clickforceBidAdapter';
import 'prebid.js/modules/enrichmentFpdModule';
import 'prebid.js/modules/gptPreAuction';
import 'prebid.js/modules/id5IdSystem';
import 'prebid.js/modules/impactifyBidAdapter';
import 'prebid.js/modules/ixBidAdapter';
import 'prebid.js/modules/lotamePanoramaIdSystem';
import 'prebid.js/modules/openxBidAdapter';
import 'prebid.js/modules/pairIdSystem';
import 'prebid.js/modules/priceFloors';
import 'prebid.js/modules/sharedIdSystem';
import 'prebid.js/modules/sizeMappingV2';
import 'prebid.js/modules/teadsBidAdapter';
import 'prebid.js/modules/teadsIdSystem';
import 'prebid.js/modules/ucfunnelBidAdapter';
import 'prebid.js/modules/uid2IdSystem';
import 'prebid.js/modules/unifiedIdSystem';
import 'prebid.js/modules/userId';
prebid.processQueue();
